import {
  Stack, Avatar, Divider, Link, useTheme,
} from '@mui/material';
import { StyledText, IconsBox } from '../GlobalStyles';
import Constants from '../constants';

function AboutSection() {
  const { breakpoints } = useTheme();
  return (
    <Stack gap={3} sx={{ marginTop: '2rem' }}>
      <StyledText
        sx={{
          fontSize: '2.5rem',
          textAlign: 'center',
          [breakpoints.down('lg')]: {
            fontSize: '2.25rem',
          },
          [breakpoints.down(980)]: {
            fontSize: '2rem',
          },
          [breakpoints.down(810)]: {
            fontSize: '1.5rem',
          },
        }}
      >
        Who are we?
      </StyledText>

      <StyledText
        sx={{
          fontWeight: '400',
          fontSize: '1.25rem',
          textAlign: 'center',
          [breakpoints.down('lg')]: {
            fontSize: '1.125rem',
          },
          [breakpoints.down(980)]: {
            fontSize: '1rem',
          },
          [breakpoints.down(810)]: {
            fontSize: '0.875rem',
          },
        }}
      >

        A team consisting of highly professional individuals with amazing
        skills in 3D, Game, Blockchain, Web and Mobile apps development.
        We believe that &quot;NFT as Utility&quot; is the way forward. So we are
        bringing quality art, tools and games to the community in the form
        of Vaping Apes on Stacks Blockchain.
        The Vaping Ape Club is OPEN! Come vApe with us.

      </StyledText>

      <IconsBox gap={{ xs: 2, sm: 5 }} sx={{ justifyContent: 'center' }}>
        {Constants.socialMediaIcons.map((iconSource, index) => (
          <Link
            key={iconSource}
            href={Constants.socialMediaURLs[index]}
            target="_blank"
          >
            <Avatar
              key={iconSource}
              src={iconSource}
              alt="icons"
              variant="square"
              sx={{
                width: '45px',
                height: '45px',
                [breakpoints.down('md')]: {
                  width: '35px',
                  height: '35px',
                },
                [breakpoints.down(450)]: {
                  width: '25px',
                  height: '25px',
                },
                transition: 'all 0.2s',
                '&:hover': {
                  transform: 'scale(1.2)',
                  cursor: 'pointer',
                },
              }}
            />
          </Link>
        ))}
      </IconsBox>

      <Divider
        variant="middle"
        sx={{ marginTop: '1rem', background: 'white', opacity: '30%' }}
      />
    </Stack>
  );
}

export default AboutSection;
