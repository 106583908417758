import { useEffect } from 'react';
import { Stack } from '@mui/material';
import HeadingContent from '../components/HeadingContent';
import LandingFooter from '../components/landing_footer/LandingFooter';
import TitleBar from '../components/title_bar/TitleBar';
import { StyledText } from '../GlobalStyles';

function TermsConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '100vh',
        background: 'black',
        paddingBottom: '1rem',
      }}
    >
      <TitleBar />

      <HeadingContent
        heading="TERMS &amp; CONDITIONS"
        content="Vaping Apes Club is a collection of digital artworks (NFTs) running on the Stacks blockchain network. This website is only an interface allowing participants to exchange digital collectibles. Users are entirely responsible for the safety and management of their own private Stack wallets and validating all transactions and contracts generated by this website before approval. This website and its connected services are provided “as is” and “as available” without warranty of any kind. By using this website you are accepting sole responsibility for any and all transactions involving Vaping Apes Club digital collectibles."
      />

      <HeadingContent
        heading="OWNERSHIP"
        content="i. You Own the NFT. Each Vaping Ape is an NFT on the Stacks blockchain. When you purchase an NFT, you own the underlying Vaping Ape, the Art, completely. Ownership of the NFT is mediated entirely by the Smart Contract and the Stacks Network: at no point may we seize, freeze, or otherwise modify the ownership of any Vaping Ape.
        ii. Personal Use. Subject to your continued compliance with these Terms, Vaping Apes Club grants you a worldwide, royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose to create or use, solely for the following purposes: (i) for your own personal, non-commercial use; (ii) as part of a marketplace that permits the purchase and sale of your Vaping Ape / NFT, provided that the marketplace cryptographically verifies each Vaping Ape owner’s rights to display the Art for their Vaping Ape to ensure that only the actual owner can display the Art; or (iii) as part of a third party website or application that permits the inclusion, involvement, or participation of your Vaping Ape, provided that the website/application cryptographically verifies each Vaping Ape owner’s rights to display the Art for their Vaping Ape to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Vaping Ape leaves the website/application.
        iii. Commercial Use. Subject to your continued compliance with these Terms, Vaping Apes Club grants you an unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative works based upon the Art (“Commercial Use”). Examples of such Commercial Use would e.g. be the use of the Art to produce and sell merchandise products (T-Shirts etc.) displaying copies of the Art.
        "
      />

      <HeadingContent
        heading=" "
        content="For the sake of clarity, nothing in this Section will be deemed to restrict you from (i) owning or operating a marketplace that permits the use and sale of VapingApes generally, provided that the marketplace cryptographically verifies each Vaping Ape owner’s rights to display the Art for their Vaping Ape to ensure that only the actual owner can display the Art; (ii) owning or operating a third party website or application that permits the inclusion, involvement, or participation of Vaping Apes generally, provided that the third party website or application cryptographically verifies each Vaping Ape owner’s rights to display the Art for their Vaping Ape to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased Vaping Ape leaves the website/application; or (iii) earning revenue from any of the foregoing."
      />

      <LandingFooter />
    </Stack>
  );
}

export default TermsConditions;
