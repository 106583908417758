import { styled } from '@mui/system';
import { Typography, Button, Box } from '@mui/material';

export const FlexCenterBox = styled(
  Box,
  {},
)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledTitle = styled(
  Typography,
  {},
)({
  fontFamily: 'wild nature',
  color: 'white',
  fontSize: '4rem',
  cursor: 'default',
});

export const StyledText = styled(
  Typography,
  {},
)({
  textAlign: 'justify',
  fontFamily: 'Open Sans',
  color: 'white',
  fontSize: '1.5rem',
  fontWeight: 600,
  cursor: 'default',
});

export const StyledButton = styled(
  Button,
  {},
)({
  maxWidth: '100%',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  background: 'rgba(9, 112, 69, 1)',
  borderRadius: 0,
  fontFamily: 'Open Sans',
  color: 'white',
  fontSize: '1.5rem',
  fontWeight: 700,
  fontStyle: 'italic',
  textTransform: 'none',
  '&:hover': {
    background: 'rgba(9, 112, 69, 0.75)',
  },
});

export const IconsBox = styled(
  Box,
  {},
)({
  display: 'flex',
});
