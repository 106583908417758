const Constants = {
  title: 'Vaping Ape Club',
  socialMediaIcons: [
    // '/images/instagramIcon.png',
    '/images/twitterIcon.png',
    '/images/discordIcon.png',
  ],
  socialMediaURLs: [
    // 'https://www.instagram.com/vapingapeclub/',
    'https://twitter.com/vapingapenft',
    'https://discord.gg/VapingApeClub',
  ],
};

export default Constants;
