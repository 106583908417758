import { Stack } from '@mui/material';
import { styled } from '@mui/system';

const StyledFooter = styled(
  Stack,
  {},
)({
  width: '100%',
  alignItems: 'center',
  paddingBottom: '1rem',
});

export default StyledFooter;
