import { Box } from '@mui/material';

function RoadMapBar({ acheived = false }) {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '200%',
        width: '6px',
        alignItems: 'stretch',
        background: 'rgba(212, 212, 212, 1)',
        transform: 'translateY(-2rem)',
      }}
    >
      <Box
        sx={{
          width: '35px',
          height: '35px',
          borderRadius: '50%',
          position: 'absolute',
          top: '25%',
          left: '-14px',
          background: acheived ? 'rgba(38, 204, 11, 1)' : '#FFFFFF',
        }}
      />
    </Box>
  );
}

export default RoadMapBar;
