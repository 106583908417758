import { styled } from '@mui/system';
import { Stack } from '@mui/material';

const MintingButtonContainer = styled(
  Stack,
  {},
)({
  maxWidth: '100%',
  background: 'rgba(9, 112, 69, 1)',
  fontFamily: 'Open Sans',
  color: 'white',
  fontSize: '1.5rem',
  fontWeight: 700,
  fontStyle: 'italic',
  textTransform: 'none',
});

export default MintingButtonContainer;
