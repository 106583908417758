import { Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from './pages/home_page/HomePage';
import LandingPage from './pages/landing_page/LandingPage';
import RoadmapPage from './pages/roadmap_page/RoadmapPage';
import TermsConditions from './pages/TermsConditions';

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
      <Route path="/roadmap" element={<RoadmapPage />} />
    </Routes>
  );
}

export default App;
