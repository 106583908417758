import { Grid, useTheme } from '@mui/material';
import RoadMapBar from '../../components/RoadMapBar';
import { StyledText } from '../../GlobalStyles';
// import * as Constants from '../../sections/roadmap_sections/Constants';

function LeftSideHeading({ heading, msg }) {
  const { breakpoints } = useTheme();

  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        alignItems: 'stretch',
        paddingTop: '5rem',
        [breakpoints.down('md')]: {
          paddingTop: '2rem',
        },
      }}
    >
      <Grid
        item
        md={5}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          [breakpoints.down('md')]: {
            justifyContent: 'start',
          },
        }}
      >
        <StyledText
          sx={{
            fontStyle: 'italic',
            fontSize: '3rem',
            textAlign: 'center',
            [breakpoints.down(1320)]: {
              fontSize: '2.875rem',
            },
            [breakpoints.down('md')]: {
              fontSize: '2rem',
            },
            [breakpoints.down(500)]: {
              fontSize: '1.5rem',
            },
          }}
        >
          {heading}
        </StyledText>
      </Grid>
      <Grid
        item
        md={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          [breakpoints.down('md')]: {
            display: 'none',
          },
        }}
      >
        <RoadMapBar />
      </Grid>

      <Grid
        item
        md={5}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <StyledText
          sx={{
            fontWeight: '400',
            fontSize: '1.125rem',
            [breakpoints.down(1220)]: {
              fontSize: '1rem',
            },
            [breakpoints.down(1055)]: {
              fontSize: '0.875rem',
            },
            [breakpoints.down(370)]: {
              fontSize: '0.75rem',
            },
          }}
        >
          {msg}
        </StyledText>
      </Grid>
    </Grid>
  );
}

export default LeftSideHeading;
