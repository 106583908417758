import { useEffect } from 'react';
import {
  Avatar, Box, Link, useTheme,
} from '@mui/material';
import TitleBar from '../../components/title_bar/TitleBar';
import MintingSection from '../../sections/minting_section/MintingSection';
import AboutSection from '../../sections/AboutSection';
import TeamSection from '../../sections/TeamSection';
import { ScreenContainer } from './HomePageStyles';
import SpecsSection from '../../sections/SpecsSection';
import HomeFooter from '../../components/home_footer/HomeFooter';
import { StyledText } from '../../GlobalStyles';

function HomePage() {
  const { breakpoints } = useTheme();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ScreenContainer gap={2}>
      <TitleBar />
      <Box
        sx={{
          width: '75rem',
          display: 'flex',
          flexDirection: 'column',
          paddingX: '3rem',
          paddingBottom: '2rem',
          justifyContent: 'center',
          alignItems: 'center',
          [breakpoints.down('lg')]: {
            width: '60rem',
            paddingX: '1rem',
          },
          [breakpoints.down(980)]: {
            width: '50rem',
          },
          [breakpoints.down(800)]: {
            width: '40rem',
          },
          [breakpoints.down(680)]: {
            width: '100%',
          },
        }}
      >
        <Avatar
          variant="square"
          src="/images/SecondBackground.avif"
          sx={{
            width: '67rem',
            height: 'auto',
            [breakpoints.down('lg')]: {
              width: '55.5rem',
            },
            [breakpoints.down(980)]: {
              width: '46rem',
            },
            [breakpoints.down(800)]: {
              width: '100%',
            },
          }}
        />
        <MintingSection />
        <AboutSection />
        <TeamSection />
        <SpecsSection />
        {/* <StyledText
          sx={{
            textAlign: 'center',
            marginTop: '2rem',
            fontSize: '1.125rem',
            break: 'all',
            [breakpoints.down(480)]: {
              fontSize: '1rem',
            },
          }}
        >
          VERIFIED SMART CONTRACT ADDRESS:
          {' '}
          <Link
            href="https://www.google.com/"
            target="_blank"
            style={{
              color: 'rgba(9, 112, 69, 1)',
              textDecoration: 'none',
              textDecorationLine: 'underline',
              overflowWrap: 'anywhere',
            }}
          >
            0xAL4DK3EjM8502A8aB7C2061c2E118A18a115t99L
          </Link>
        </StyledText> */}
      </Box>
      <HomeFooter />
    </ScreenContainer>
  );
}

export default HomePage;
