import { useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import TitleBar from '../../components/title_bar/TitleBar';
import ScreenContainer from './RoadmapPageStyle';
import HomeFooter from '../../components/home_footer/HomeFooter';
import RightSideHeading from '../../sections/roadmap_sections/RightSideHeading';
import LeftSideHeading from '../../sections/roadmap_sections/LeftSideHeading';
import * as Constants from '../../sections/roadmap_sections/Constants';

function RoadmapPage() {
  const { breakpoints } = useTheme();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ScreenContainer gap={2}>
      <TitleBar roadmapButton={false} />
      <Box
        sx={{
          width: '75rem',
          display: 'flex',
          flexDirection: 'column',
          paddingX: '3rem',
          paddingBottom: '10rem',
          justifyContent: 'center',
          alignItems: 'center',
          [breakpoints.down(1220)]: {
            width: '60rem',
            paddingX: '1rem',
          },
          [breakpoints.down(1055)]: {
            width: '50rem',
          },
          [breakpoints.down('md')]: {
            width: '40rem',
            paddingBottom: '2rem',
          },
          [breakpoints.down(700)]: {
            width: '30rem',
          },
          [breakpoints.down('sm')]: {
            width: '100%',
            paddingX: '1rem',
          },
        }}
      >
        <RightSideHeading heading={Constants.heading1} msg={Constants.message1} />
        <LeftSideHeading heading={Constants.heading2} msg={Constants.message2} />

        <RightSideHeading heading={Constants.heading3} msg={Constants.message3} />
        <LeftSideHeading heading={Constants.heading4} msg={Constants.message4} />

        <RightSideHeading heading={Constants.heading5} msg={Constants.message5} />
        <LeftSideHeading heading={Constants.heading6} msg={Constants.message6} />

        <RightSideHeading heading={Constants.heading7} msg={Constants.message7} />
        <LeftSideHeading heading={Constants.heading8} msg={Constants.message8} />

        <RightSideHeading heading={Constants.heading9} msg={Constants.message9} />
        <LeftSideHeading heading={Constants.heading10} msg={Constants.message10} />

        <RightSideHeading heading={Constants.heading11} msg={Constants.message11} />
        <LeftSideHeading heading={Constants.heading12} msg={Constants.message12} />

      </Box>
      <HomeFooter />
    </ScreenContainer>
  );
}

export default RoadmapPage;
