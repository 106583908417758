import { Stack, Avatar, Link as MUILink } from '@mui/material';
import { Link } from 'react-router-dom';
import StyledFooter from './HomeFooterStyle';
import { StyledText, IconsBox } from '../../GlobalStyles';
import Constants from '../../constants';

function HomeFooter() {
  return (
    <StyledFooter gap={1}>
      <IconsBox gap={2}>
        {Constants.socialMediaIcons.map((iconSource, index) => (
          <MUILink
            key={iconSource}
            href={Constants.socialMediaURLs[index]}
            target="_blank"
          >
            <Avatar
              key={iconSource}
              src={iconSource}
              alt="icons"
              variant="square"
              sx={{
                width: '25px',
                height: '26px',
                transition: 'all 0.2s',
                '&:hover': {
                  transform: 'scale(1.3)',
                  cursor: 'pointer',
                },
              }}
            />
          </MUILink>
        ))}
      </IconsBox>
      <Stack>
        <StyledText
          sx={{
            textAlign: 'start',
            fontSize: '0.875rem',
          }}
        >
          &copy; 2022
          {' '}
          {Constants.title}
        </StyledText>
        <Link to="/terms-conditions" style={{ textDecoration: 'none' }}>
          <StyledText
            sx={{
              cursor: 'pointer',
              textAlign: 'start',
              fontSize: '0.875rem',
              color: 'rgba(9, 112, 69, 1)',
              transition: 'all 0.2s',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
          >
            VAC Terms &amp; Conditions
          </StyledText>
        </Link>
      </Stack>
    </StyledFooter>
  );
}

export default HomeFooter;
