import { Stack } from '@mui/material';
import { styled } from '@mui/system';

const StyledFooter = styled(
  Stack,
  {},
)({
  width: '95%',
  marginTop: '2rem',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export default StyledFooter;
