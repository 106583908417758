import { Stack, Avatar, useTheme } from '@mui/material';
import { StyledText } from '../GlobalStyles';

function TeamCard({ memberNFT, memberName, memberDescription }) {
  const { breakpoints } = useTheme();
  return (
    <Stack sx={{ alignItems: 'center' }}>
      <Avatar
        variant="square"
        src={memberNFT}
        sx={{
          width: '245px',
          height: '245px',
          [breakpoints.down(1290)]: {
            width: '200px',
            height: '200px',
          },
          [breakpoints.down(1050)]: {
            width: '170px',
            height: '170px',
          },
        }}
      />
      <StyledText
        sx={{
          textAlign: 'center',
          fontSize: '1.25rem',
          [breakpoints.up('sm')]: {
            fontSize: '1.5rem',
          },
          [breakpoints.up(950)]: {
            fontSize: '1.75rem',
          },
          [breakpoints.up('lg')]: {
            fontSize: '2rem',
          },
        }}
      >
        {memberName}
      </StyledText>
      <StyledText
        sx={{
          textAlign: 'center',
          fontWeight: '400',
          fontSize: '1rem',
          [breakpoints.up('md')]: {
            fontSize: '1rem',
          },
          [breakpoints.up('lg')]: {
            fontSize: '1.125rem',
          },
        }}
      >
        {memberDescription}
      </StyledText>
    </Stack>
  );
}

export default TeamCard;
