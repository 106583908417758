import { Box, Divider, useTheme } from '@mui/material';
import Banner from '../../components/banner/Banner';
import ScreenContainer from './LandingPageStyles';
import { StyledTitle, StyledText } from '../../GlobalStyles';
import HomeFooter from '../../components/home_footer/HomeFooter';
import Constants from '../../constants';

function LandingPage() {
  const { breakpoints } = useTheme();

  return (
    <ScreenContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          maxWidth: '75rem',
          [breakpoints.down('lg')]: {
            width: '60rem',
          },
          [breakpoints.down(980)]: {
            width: '50rem',
          },
          [breakpoints.down(815)]: {
            width: '40rem',
          },
          [breakpoints.down(650)]: {
            width: '100%',
          },
        }}
      >
        {/* Title */}
        <Box sx={{ width: '100%', position: 'relative' }}>
          <StyledTitle
            sx={{
              width: '100%',
              textAlign: 'center',
              position: 'absolute',
              zIndex: 1,
              [breakpoints.down(980)]: {
                fontSize: '3.875rem',
              },
              [breakpoints.down(815)]: {
                fontSize: '3.25rem',
              },
              [breakpoints.down(650)]: {
                fontSize: '2.875rem',
              },
              transition: 'all 0.2s',
              '&:hover': {
                letterSpacing: 1.5,
              },
            }}
          >
            {Constants.title}
          </StyledTitle>
        </Box>

        {/* Banner */}
        <Banner />

        {/* Intro */}
        <StyledText
          sx={{
            width: '75%',
            fontSize: '1rem',
            fontWeight: '400',
            textAlign: 'end',
            marginTop: '2rem',
            [breakpoints.down(980)]: {
              width: '100%',
              paddingX: '1rem',
            },
            [breakpoints.down(815)]: {
              marginTop: '3rem',
            },
            [breakpoints.down(650)]: {
              textAlign: 'center',
            },
          }}
        >
          Limited collection consisting of 3333 unique Vaping Apes which are procedurally
          generated from around 150 amazing &amp; fun traits. The club is open!
          Come vApe with us!
        </StyledText>
      </Box>

      {/* Footer */}
      <Divider
        sx={{
          width: '90%',
          background: 'rgba(9, 112, 69, 1)',
          marginY: '2rem',
        }}
      />
      <HomeFooter />
    </ScreenContainer>
  );
}

export default LandingPage;
