import { Stack } from '@mui/material';
import { styled } from '@mui/system';

const ScreenContainer = styled(
  Stack,
  {},
)({
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'black',
  paddingBottom: '1rem',
});

export default ScreenContainer;
