import { Grid } from '@mui/material';
import { styled } from '@mui/system';

const ContentGrid = styled(
  Grid,
  {},
)({
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1rem',
});

export default ContentGrid;
