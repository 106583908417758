import {
  Grid, Stack, Divider, useTheme,
} from '@mui/material';
import TeamCard from '../components/TeamCard';
import { StyledText } from '../GlobalStyles';

const team = [
  {
    nft: '/images/DHamburger.avif',
    name: '[ Vapelord ] DHamburger',
    description:
      'Loves to fiddle with Vapes. Always working to improve vapes quality and quantity. He is the one that makes sure our club gets top-notch quality vApes.',
  },
  {
    nft: '/images/SirVapesalot.avif',
    name: '[ Vapist ] SirVapesalot',
    description:
      'As the name suggests, the guy loves his vape. He makes sure Vapes get to the club in time to be served to the club members. He is the backbone of the supply chain.',
  },
  {
    nft: '/images/Duchess.avif',
    name: '[ Vapender ] Duchess Of Rendering',
    description:
      'Vapender is the core of the club. Afterall without her, the club and everything in it wouldn\'t even exist? Heck I wouldn\'t even be typing this right now.',
  },
  {
    nft: '/images/Mistiff.avif',
    name: '[ Vapologist ] Mistiff',
    description:
      'As the title suggests, the guy reads vApe\'s minds and brings their desires into existence by providing them quality service upgrades so that things work smoothly around the club.',
  },
];

function TeamSection() {
  const { breakpoints } = useTheme();
  return (
    <Stack gap={3} sx={{ marginTop: '2rem' }}>
      <StyledText
        sx={{
          fontSize: '2.5rem',
          textAlign: 'center',
          [breakpoints.down('lg')]: {
            fontSize: '2.25rem',
          },
          [breakpoints.down(980)]: {
            fontSize: '2rem',
          },
          [breakpoints.down(810)]: {
            fontSize: '1.5rem',
          },
        }}
      >
        The Team
      </StyledText>

      <Grid container justifyContent="space-around" rowGap={2} columnGap={1}>
        {team.map((member) => (
          <Grid item sm={5} md={2} key={member.nft}>
            <TeamCard
              memberNFT={member.nft}
              memberName={member.name}
              memberDescription={member.description}
            />
          </Grid>
        ))}
      </Grid>

      <Divider
        variant="middle"
        sx={{ marginTop: '1rem', background: 'white', opacity: '30%' }}
      />
    </Stack>
  );
}

export default TeamSection;
