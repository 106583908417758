import {
  Stack,
  Box,
  Avatar,
  Divider,
  useTheme,
  Link as MUILink,
} from '@mui/material';
import { Link } from 'react-router-dom';
import StyledFooter from './LandingFooterStyle';
import { StyledText, IconsBox } from '../../GlobalStyles';
import Constants from '../../constants';

function LandingFooter() {
  const { breakpoints } = useTheme();
  return (
    <StyledFooter>
      <Divider
        sx={{
          width: '90%',
          background: 'rgba(9, 112, 69, 1)',
          marginTop: '2rem',
        }}
      />

      <Box
        sx={{
          width: '100%',
          marginTop: '2rem',
          display: 'flex',
          justifyContent: ' space-between',
        }}
      >
        <Stack>
          <StyledText
            sx={{
              textAlign: 'start',
              fontSize: '0.875rem',
              [breakpoints.down('sm')]: {
                fontSize: '0.75rem',
              },
            }}
          >
            &copy; 2022
            {' '}
            {Constants.title}
          </StyledText>
          <Link to="/terms-conditions" style={{ textDecoration: 'none' }}>
            <StyledText
              sx={{
                cursor: 'pointer',
                textAlign: 'start',
                fontSize: '0.875rem',
                color: 'rgba(9, 112, 69, 1)',
                [breakpoints.down('sm')]: {
                  fontSize: '0.75rem',
                },
                transition: 'all 0.2s',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
              }}
            >
              VAC Terms &amp; Conditions
            </StyledText>
          </Link>
        </Stack>

        <IconsBox gap={{ xs: 1, sm: 2 }}>
          {Constants.socialMediaIcons.map((iconSource, index) => (
            <MUILink
              key={iconSource}
              href={Constants.socialMediaURLs[index]}
              target="_blank"
            >
              <Avatar
                key={iconSource}
                src={iconSource}
                alt="icons"
                variant="square"
                sx={{
                  width: '20px',
                  height: '21px',
                  [breakpoints.down('sm')]: {
                    width: '16px',
                    height: '17px',
                  },
                  transition: 'all 0.2s',
                  '&:hover': {
                    transform: 'scale(1.2)',
                    cursor: 'pointer',
                  },
                }}
              />
            </MUILink>
          ))}
        </IconsBox>
      </Box>
    </StyledFooter>
  );
}

export default LandingFooter;
