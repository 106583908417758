import { Stack, Box, Grid } from '@mui/material';
import { styled } from '@mui/system';

export const ScreenContainer = styled(
  Stack,
  {},
)({
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'space-around',
  background: 'black',
});

export const Banner = styled(
  Box,
  {},
)({
  height: '75vh',
  backgroundImage: 'url(/images/banner.jpg)',
  backgroundSize: 'cover',
});

export const ContentGrid = styled(
  Grid,
  {},
)({
  width: '85%',
  background: 'rgba(0,0,0,0.8)',
  borderRadius: '20px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '3rem',
});
