import { styled } from '@mui/system';
import { Box } from '@mui/material';

const TitleBarContainer = styled(
  Box,
  {},
)({
  width: '70%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export default TitleBarContainer;
