import {
  Stack, Box, useTheme, CardMedia,
} from '@mui/material';

import { Link } from 'react-router-dom';
import { StyledButton, StyledText } from '../../GlobalStyles';

function Banner() {
  const { breakpoints } = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        marginTop: '3.55rem',
        position: 'relative',
        [breakpoints.down(815)]: {
          marginTop: '3rem',
        },
        [breakpoints.down(650)]: {
          marginTop: '2.55rem',
        },
      }}
    >
      <CardMedia
        component="video"
        loop
        muted
        autoPlay
        src="/images/banner.mp4"
        sx={{
          width: '100%',
          height: 'auto',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '25rem',
          paddingY: '1rem',
          background: 'rgb(0,0,0,0.8)',
          [breakpoints.up(980)]: {
            position: 'absolute',
            top: '100%',
            left: '100%',
            transform: 'translate(-101.5%,-102%)',
          },
          [breakpoints.down('lg')]: {
            width: '23rem',
          },
          [breakpoints.down(980)]: {
            width: '80%',
            paddingRight: '1rem',
            justifyContent: 'end',
          },
          [breakpoints.down(400)]: {
            width: '100%',
            paddingLeft: '1rem',
          },
        }}
      >
        <Stack
          gap={2}
          sx={{
            alignItems: 'center',
            width: '80%',
            [breakpoints.down(600)]: {
              width: '100%',
            },
            [breakpoints.down(980)]: {
              alignItems: 'end',
            },
          }}
        >
          <StyledText
            sx={{
              fontStyle: 'italic',
              fontSize: '2.25rem',
              textAlign: 'end',
              lineHeight: '1',
            }}
          >
            Welcome to the
            {' '}
            <br />
            {' '}
            Vaping Ape Club
          </StyledText>

          <Link to="/home" style={{ width: '100%', textDecoration: 'none' }}>
            <StyledButton variant="contained" sx={{ width: '100%' }}>
              Enter
            </StyledButton>
          </Link>
        </Stack>
      </Box>
    </Box>
  );
}

export default Banner;
