import { Stack, Box, Grid } from '@mui/material';
import { styled } from '@mui/system';

const ScreenContainer = styled(
  Stack,
  {},
)({
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'space-around',
  background: 'black',
});

export default ScreenContainer;
