import { Stack, Divider, useTheme } from '@mui/material';
import { StyledText } from '../GlobalStyles';

function SpecsSection() {
  const { breakpoints } = useTheme();
  return (
    <Stack gap={3} sx={{ marginTop: '2rem' }}>
      <StyledText
        sx={{
          fontSize: '2.5rem',
          textAlign: 'start',
          [breakpoints.down('lg')]: {
            fontSize: '2.25rem',
          },
          [breakpoints.down(980)]: {
            fontSize: '2rem',
          },
          [breakpoints.down(810)]: {
            fontSize: '1.5rem',
          },
        }}
      >
        The Specs
      </StyledText>

      <StyledText
        sx={{
          fontWeight: '400',
          fontSize: '1.25rem',
          textAlign: 'start',
          [breakpoints.down('lg')]: {
            fontSize: '1.125rem',
          },
          [breakpoints.down(980)]: {
            fontSize: '1rem',
          },
          [breakpoints.down(810)]: {
            fontSize: '0.875rem',
          },
        }}
      >
        All Vaping Apes are generated programatically using around 150 amazing
        and fun traits.
        Each Vaping Ape comprises of 11 layers. The collection is launched on Stacks
        and Gamma.io is the magical marketplace of choice.
        We don&apos;t have to worry about smart contract on Gamma.io so let&apos;s
        skip that hectic part for good.
        The launch price of a Vaping Ape is 40 STX for Pre-sale and 45 STX for Public sale.
        Each owner enjoys exclusive memership of The Club with lot of benefits from
        the roadmap and more.
      </StyledText>

      <Divider
        variant="middle"
        sx={{ marginTop: '1rem', background: 'white', opacity: '30%' }}
      />
    </Stack>
  );
}

export default SpecsSection;
