import store from '../store';

const fetchDataRequest = () => ({
  type: 'CHECK_DATA_REQUEST',
});

const fetchDataSuccess = (payload) => ({
  type: 'CHECK_DATA_SUCCESS',
  payload,
});

const fetchDataFailed = (payload) => ({
  type: 'CHECK_DATA_FAILED',
  payload,
});

const fetchData = () => async (dispatch) => {
  dispatch(fetchDataRequest());
  try {
    const totalSupply = await store
      .getState()
      .blockchain.smartContract.methods.totalSupply()
      .call();

    dispatch(
      fetchDataSuccess({
        totalSupply,
      }),
    );
  } catch (err) {
    console.log(err);
    dispatch(fetchDataFailed('Could not load data from contract'));
  }
};

export default fetchData;
