const initialState = {
  loading: false,
  account: null,
  smartContract: null,
  web3: null,
  errorMsg: '',
};

// eslint-disable-next-line default-param-last
const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CONNECTION_REQUEST':
      return {
        ...initialState,
        loading: true,
      };
    case 'CONNECTION_SUCCESS':
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        smartContract: action.payload.smartContract,
        web3: action.payload.web3,
      };
    case 'CONNECTION_FAILED':
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case 'SET_SMART_CONTRACT_SUCCESS':
      return {
        ...state,
        loading: false,
        smartContract: action.payload.smartContract,
      };
    case 'SET_SMART_CONTRACT_FAILED':
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case 'UPDATE_ACCOUNT':
      return {
        ...state,
        account: action.payload.account,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
