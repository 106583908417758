export const heading1 = 'Social Media Activation - Done';
export const heading2 = 'Upload Collection - Done';
// export const heading2a = 'Upload Collection';
// export const heading2b = 'In Progress';
export const heading3 = 'Whitelist 1 - Done';
export const heading4 = 'Whitelist 2 - Done';
export const heading5 = 'Collabs & Giveaways - In Progress';
export const heading6 = 'Website';
export const heading7 = 'Minting - Live';
export const heading8 = 'Community Tools - In Progress';
export const heading9 = '3D Products - In Progress';
export const heading10 = 'P2E STX Game - In Progress';
export const heading11 = 'Merchandise - Coming Soon';
export const heading12 = 'Metaverse - Coming Soon';

export const message1 = 'Creation and activation of Discord and Twitter has successfully been completed';
export const message2 = 'Getting the Vaping Apes Club NFT collection live on gamma.io';
export const message3 = 'First whitelist has been successfully completed. 300 WL spots opened up for grabs! Every whitelisted address got 1 claim by default. As a bonus, everyone accepted on the first whitelist also got  a chance to grab 2 additional claims each by helping our discord community grow';
export const message4 = 'Opened up the second chapter of our whitelist. Increased the number of whitelist claims available. More spots for our amazing community members are now up for grab. Closing soon...!!!';
export const message5 = 'Collabs & Giveaways - Twitter, Discord & OG Giveaways are in progress. Already given away 16 NFTs & many WL spots. More exciting collabs and giveaways upcoming!';
export const message6 = 'You are reading this, arent you? :) Btw we will be updating it often';
export const message7 = 'Minting is now LIVE!';
export const message8 = 'Community tools/projects - One of our key aspirations is to identify gaps in the community’s experience for newcomers and existing members. Gaps and friction that can be mitigated with access to the right tools; making it easier than ever to get going within the Stacks ecosystem.';
export const message9 = 'Future NFT projects - Our team has stellar 3D skills and we would love to show those off in our upcoming NFT collections and products. And it goes without saying, our existing community members and Vaping Ape holders get special benefits.';
export const message10 = 'P2E Vaping Ape Game - We have a couple of nifty concepts in the works. Watch this space for more updates!';
export const message11 = 'Merchandise - Merch Store will be unlocked.';
export const message12 = 'Metaverse - Vaping Ape owners get exclusive membership & access to the VA Club - A space in the metaverse to lounge and chill with other members in real time!';
