import { Stack, useTheme } from '@mui/material';
import { StyledText } from '../GlobalStyles';

function HeadingContent({ heading, content }) {
  const { breakpoints } = useTheme();

  return (
    <Stack
      sx={{
        width: '70%',
        marginTop: '3rem',
        [breakpoints.down('sm')]: {
          width: '90%',
        },
      }}
    >
      <StyledText
        sx={{
          fontSize: '2.5rem',
          fontStyle: 'italic',
          [breakpoints.down('lg')]: {
            fontSize: '2.25rem',
          },
          [breakpoints.down('md')]: {
            fontSize: '2rem',
          },
          [breakpoints.down('sm')]: {
            fontSize: '1.5rem',
          },
          [breakpoints.down(430)]: {
            fontSize: '1.25rem',
          },
        }}
      >
        {heading}
      </StyledText>

      <StyledText
        sx={{
          marginTop: '2rem',
          fontWeight: '400',
          fontSize: '1.25rem',
          [breakpoints.down('lg')]: {
            fontSize: '1.125rem',
          },
          [breakpoints.down('md')]: {
            fontSize: '1rem',
            marginTop: '1.5rem',
          },
          [breakpoints.down('sm')]: {
            fontSize: '0.875rem',
            textAlign: 'start',
            marginTop: '1rem',
          },
          [breakpoints.down(430)]: {
            fontSize: '0.75rem',
          },
        }}
      >
        {content}
      </StyledText>
    </Stack>
  );
}

export default HeadingContent;
