import { useTheme, Avatar, Link as MUILink } from '@mui/material';
import { Link } from 'react-router-dom';
import TitleBarContainer from './TitleBarStyle';
import { StyledTitle, StyledButton, IconsBox } from '../../GlobalStyles';
import Constants from '../../constants';

function TitleBar({ roadmapButton = true }) {
  const { breakpoints } = useTheme();
  return (
    <TitleBarContainer
      gap={1}
      sx={{
        paddingTop: '1rem',
        [breakpoints.down('sm')]: {
          width: '90%',
        },
      }}
    >
      <Link to="/" style={{ textDecoration: 'none' }}>
        <StyledTitle
          sx={{
            cursor: 'pointer',
            fontSize: '3rem',
            [breakpoints.down('lg')]: {
              fontSize: '2.75rem',
            },
            [breakpoints.down('md')]: {
              fontSize: '2.25rem',
            },
            [breakpoints.down(680)]: {
              fontSize: '1.875rem',
            },
            [breakpoints.down(500)]: {
              fontSize: '1.5rem',
            },
            transition: 'all 0.2s',
            '&:hover': {
              letterSpacing: 1.5,
            },
          }}
        >
          {Constants.title}
        </StyledTitle>
      </Link>

      <IconsBox gap={{ xs: 1, md: 2 }} sx={{ alignItems: 'center' }}>
        <Link to="/roadmap" style={{ textDecoration: 'none' }}>
          <StyledButton
            variant="contained"
            sx={{
              fontStyle: 'italic',
              fontSize: '1.5rem',
              paddingX: '4rem',
              display: roadmapButton ? 'block' : 'none',
              [breakpoints.down('lg')]: {
                fontSize: '1.25rem',
                paddingX: '3rem',
              },
              [breakpoints.down('md')]: {
                fontSize: '1.125rem',
                paddingX: '2.25rem',
              },
              [breakpoints.down(680)]: {
                fontSize: '1rem',
                paddingX: '1.5rem',
              },
              [breakpoints.down(500)]: {
                fontSize: '0.6rem',
                paddingX: '0.25rem',
                paddingY: '0.25rem',
              },
            }}
          >
            Roadmap
          </StyledButton>
        </Link>
        {Constants.socialMediaIcons.map((iconSource, index) => (
          <MUILink
            key={iconSource}
            href={Constants.socialMediaURLs[index]}
            target="_blank"
          >
            <Avatar
              key={iconSource}
              src={iconSource}
              alt="icons"
              variant="square"
              sx={{
                width: '20px',
                height: '21px',
                [breakpoints.down(1000)]: {
                  width: '18px',
                  height: '18px',
                },
                [breakpoints.down(500)]: {
                  width: '13px',
                  height: '14px',
                },
                transition: 'all 0.2s',
                '&:hover': {
                  transform: 'scale(1.2)',
                  cursor: 'pointer',
                },
              }}
            />
          </MUILink>
        ))}
      </IconsBox>
    </TitleBarContainer>
  );
}

export default TitleBar;
