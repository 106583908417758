import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Stack, Grid, Avatar, Divider, useTheme,
} from '@mui/material';
import {
  connect,
  setSmartContract,
} from '../../redux/blockchain/blockchainActions';
import fetchData from '../../redux/data/dataActions';
import ContentGrid from '../SectionStyle';
import { StyledText, StyledButton } from '../../GlobalStyles';
import MintingButtonContainer from './MintingSectionStyle';

function MintingSection() {
  const { breakpoints } = useTheme();
  const [CONFIG, SET_CONFIG] = useState(null);
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState('Click buy to mint your NFT');
  const [mintAmount, setMintAmount] = useState(1);

  const getConfig = async () => {
    const configResponse = await fetch('/config/config.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  const claimNFTs = () => {
    const cost = CONFIG?.WEI_COST;
    const gasLimit = CONFIG?.GAS_LIMIT;
    const totalCostWei = String(cost * mintAmount);
    const totalGasLimit = String(gasLimit * mintAmount);
    setFeedback(`Minting your ${CONFIG?.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG?.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once('error', (err) => {
        setFeedback('Sorry, something went wrong please try again later');
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback('Congrats! Visit Opensea.io to view your purchase');
        setClaimingNft(false);
        dispatch(fetchData());
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.smartContract !== null) {
      dispatch(fetchData());
    }
  };

  useEffect(() => {
    dispatch(setSmartContract());
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.smartContract]);

  return (
    <Stack gap={3} sx={{ marginTop: '2rem' }}>
      <ContentGrid container gap={{ xs: 2, md: 10 }}>
        <Grid
          item
          sm={4}
          textAlign="center"
          sx={{
            [breakpoints.between(460, 'sm')]: {
              paddingX: '6rem',
            },
            [breakpoints.down(460)]: {
              paddingX: '2rem',
            },
          }}
        >
          <Avatar
            src="/images/MintingApe.avif"
            variant="square"
            sx={{ width: '100%', height: '100%', borderRadius: '20px' }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm
          sx={{
            display: 'flex',
            flexDirection: 'column',
            [breakpoints.down('sm')]: {
              alignItems: 'center',
            },
          }}
        >
          <StyledText
            sx={{
              fontSize: '2.5rem',
              textAlign: 'start',
              [breakpoints.down('lg')]: {
                fontSize: '2.25rem',
              },
              [breakpoints.down(980)]: {
                fontSize: '2rem',
              },
              [breakpoints.down(810)]: {
                fontSize: '1.5rem',
              },
              [breakpoints.down('sm')]: {
                textAlign: 'center',
              },
            }}
          >
            {/* {data?.totalSupply}
            {' '}
            out of
            {' '}
            {CONFIG?.MAX_SUPPLY}
            {' '}
            sold */}
            How do I get a Vaping Ape?
          </StyledText>

          <StyledText
            sx={{
              fontWeight: '400',
              fontSize: '1.25rem',
              textAlign: 'start',
              [breakpoints.down('lg')]: {
                fontSize: '1.125rem',
              },
              [breakpoints.down(980)]: {
                fontSize: '1rem',
              },
              [breakpoints.down('sm')]: {
                textAlign: 'center',
              },
              [breakpoints.down(810)]: {
                fontSize: '0.875rem',
              },
            }}
          >
            To get your Vaping Ape, click the button below and visit
            Gamma.io to check out the collection.
          </StyledText>

          <Stack gap={1} direction="row">
            {blockchain?.account !== null && (
              <MintingButtonContainer
                direction="row"
                gap={{ xs: 3, sm: 5, lg: 8 }}
                sx={{
                  marginTop: '1rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '14rem',
                  [breakpoints.down('lg')]: {
                    width: '10rem',
                  },
                  [breakpoints.down('sm')]: {
                    width: '8rem',
                  },
                }}
              >
                <StyledText
                  sx={{
                    cursor: 'pointer',
                    [breakpoints.down('sm')]: {
                      fontSize: '1rem',
                    },
                  }}
                  onClick={() => {
                    claimingNft ? null : decrementMintAmount();
                  }}
                >
                  -
                </StyledText>
                <StyledText
                  sx={{
                    cursor: 'default',
                    [breakpoints.down('sm')]: {
                      fontSize: '1rem',
                    },
                  }}
                >
                  {mintAmount}
                </StyledText>
                <StyledText
                  sx={{
                    cursor: 'pointer',
                    [breakpoints.down('sm')]: {
                      fontSize: '1rem',
                    },
                  }}
                  onClick={() => {
                    claimingNft ? null : incrementMintAmount();
                  }}
                >
                  +
                </StyledText>
              </MintingButtonContainer>
            )}

            <StyledButton
              onClick={() => {
                // blockchain?.account === null
                //   ? dispatch(connect())
                //   : !claimingNft && claimNFTs();
                window.open('https://create.gamma.io/collection/vaping-ape', '_blank');
              }}
              variant="contained"
              sx={{
                marginTop: '1rem',
                width: '14rem',
                fontStyle: 'italic',
                [breakpoints.down('lg')]: {
                  fontSize: '1rem',
                  width: '10rem',
                },
                [breakpoints.down('sm')]: {
                  fontSize: '0.75rem',
                  width: '8rem',
                },
              }}
            >
              {/* {blockchain?.account === null
                ? 'Connect Wallet'
                : claimingNft
                  ? 'Busy'
                  : 'Buy'} */}
              Gamma.io
            </StyledButton>
          </Stack>

          {blockchain.errorMsg !== '' ? (
            <StyledText
              sx={{
                marginTop: '0.5rem',
                textAlign: 'start',
                fontSize: '0.875rem',
              }}
            >
              {blockchain?.errorMsg}
            </StyledText>
          ) : null}
        </Grid>
      </ContentGrid>
      <Divider
        variant="middle"
        sx={{ marginTop: '1rem', background: 'white', opacity: '30%' }}
      />
    </Stack>
  );
}

export default MintingSection;
